<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <notification-dropdown />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{
                getUserData != null
                  ? `${getUserData.firstName} ${getUserData.lastName}`
                  : $t("Please Login")
              }}
            </p>
            <span class="user-status">{{ authType }}</span>
          </div>
          <b-avatar
            v-if="getUserData"
            size="40"
            variant="light-primary"
            badge
            :src="getUserData.image"
            class="badge-minimal"
            badge-variant="success"
          />
          <b-avatar
            v-else
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          v-if="menuService==='agent'"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="ShoppingBagIcon"
            class="mr-50"
          />
          <span>{{ $t("Agent") }}</span>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>{{ $t("Profile") }}</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>{{ $t("Inbox") }}</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>{{ $t("Task") }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="chatPage"
        >
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>{{ $t("Chat") }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          v-if="getUserData != null"
          link-class="d-flex align-items-center"
          @click="logoutToType"
        >
          <feather-icon
            v-if="getUserData.userType === menuService"
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <feather-icon
            v-if="getUserData.userType !== menuService"
            size="16"
            icon="ArrowLeftIcon"
            class="mr-50"
          />
          <span v-if="getUserData.userType === menuService">{{ $t("Logout") }}</span>
          <span v-if="getUserData.userType !== menuService">{{ $t("Back") }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="getUserData == null"
          link-class="d-flex align-items-center"
          @click="login"
        >
          <feather-icon
            size="16"
            icon="LogInIcon"
            class="mr-50"
          />
          <span>{{ $t("Login") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown
        v-if="menuService == 'agent'"
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{
                navbarTitle
              }}
            </p>
            <span class="user-status">{{ $t(menuService.toUpperCase()) }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="menuService == 'agent' ? getAgentData.image : ''"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          v-if="getUserData != null"
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t("Logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import {
  // eslint-disable-next-line no-unused-vars
  initialAbility, rootAbility, agentAbility,
} from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import Locale from '@/layouts/components/app-navbar/Locale.vue'
import NotificationDropdown from '@/layouts/components/app-navbar/NotificationDropdown.vue'
import store from '@/store'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,

    // Locale
    Locale,
    NotificationDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    menuService() {
      return store.state.appConfig.menuService
    },
    navbarTitle() {
      return store.state.appConfig.navbarTitle
    },
    authType() {
      if (this.getUserData !== null) {
        if (this.getUserData.userType.toUpperCase() === this.menuService.toUpperCase()) {
          return this.$t(this.menuService.toUpperCase())
        }
        return `${this.$t(this.menuService.toUpperCase())} (${this.$t(this.getUserData.userType.toUpperCase())})`
      }
      return `${this.$t(this.menuService.toUpperCase())}`
    },
    getUserData() {
      return localStorage.getItem('userData') != null
        ? JSON.parse(localStorage.getItem('userData'))
        : null
    },
    getAgentData() {
      return localStorage.getItem('agentData') != null
        ? JSON.parse(localStorage.getItem('agentData'))
        : null
    },
  },
  methods: {
    chatPage() {
      // Redirect to login page
      if (this.menuService === 'agent') {
        this.$router.push({ name: 'agent-chat' })
      } else if (this.menuService === 'root') {
        this.$router.push({ name: 'root-chat' })
      }
    },
    logoutToType() {
      // Redirect to login page
      if (this.getUserData.userType === 'root') {
        // Reset ability
        // this.$ability.update(initialAbility)
        if (this.menuService === 'root') {
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

          // Remove userData from localStorage
          localStorage.removeItem('userData')
          localStorage.removeItem('role')
          localStorage.removeItem('ability')
          localStorage.removeItem('agentId')
          localStorage.removeItem('agentData')

          // Reset ability
          this.$ability.update(initialAbility)
          this.$router.replace({ name: 'auth-login' })
        } else {
          this.$ability.update(rootAbility())
          localStorage.setItem('ability', JSON.stringify(rootAbility()))

          this.$router.replace({ name: 'root-agents' })
        }
      } else if (this.getUserData.userType === 'agent') {
        // Reset ability
        // this.$ability.update(initialAbility)
        if (this.menuService === 'agent') {
          // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

          // Remove userData from localStorage
          localStorage.removeItem('userData')
          localStorage.removeItem('role')
          localStorage.removeItem('ability')
          localStorage.removeItem('agentId')
          localStorage.removeItem('agentData')

          // Reset ability
          this.$ability.update(initialAbility)
          this.$router.replace({ name: 'auth-login' })
          store.commit('appConfig/UPDATE_MENU_SERVICE', '')
        }
      }
    },
    logout() {
      // Redirect to login page
      if (this.menuService === 'agent') {
        if (this.getUserData.userType === 'root') {
          localStorage.removeItem('agentId')
          localStorage.removeItem('agentData')
          // Reset ability
          this.$ability.update(rootAbility())
          localStorage.setItem('ability', JSON.stringify(rootAbility()))

          this.$router.replace({ name: 'root-agents' })
        } else if (this.getUserData.userType === 'agent') {
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

          // Remove userData from localStorage
          localStorage.removeItem('userData')
          localStorage.removeItem('role')
          localStorage.removeItem('ability')
          localStorage.removeItem('agentId')
          localStorage.removeItem('agentData')

          // Reset ability
          this.$ability.update(initialAbility)
          localStorage.setItem('ability', JSON.stringify(initialAbility))

          this.$router.replace({ name: 'auth-login' })
          store.commit('appConfig/UPDATE_MENU_SERVICE', '')
        }
      } else if (this.menuService === 'root') {
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        // Remove userData from localStorage
        localStorage.removeItem('userData')
        localStorage.removeItem('role')
        localStorage.removeItem('agentId')
        localStorage.removeItem('agentData')

        // Reset ability
        this.$ability.update(initialAbility)
        this.$router.replace({ name: 'auth-login' })
        store.commit('appConfig/UPDATE_MENU_SERVICE', '')
      }
    },
    login() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('role')
      localStorage.removeItem('agentId')
      localStorage.removeItem('agentData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.replace({ name: 'auth-login' })
      store.commit('appConfig/UPDATE_MENU_SERVICE', '')
    },
  },
}
</script>
