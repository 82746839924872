<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        v-if="menuService === 'root'"
        :badge="notifications.length + systemNotifications.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
      <feather-icon
        v-else
        :badge="notifications.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('Notifications') }}
        </h4>
        <b-badge
          v-if="menuService === 'root'"
          pill
          variant="light-primary"
        >
          {{ notifications.length + systemNotifications.length }} {{ $t('New') }}
        </b-badge>
        <b-badge
          v-else
          pill
          variant="light-primary"
        >
          {{ notifications.length }} {{ $t('New') }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <!-- <vue-perfect-scrollbar
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-link
        v-for="notification in notifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.avatar"
              :text="notification.avatar"
              :variant="notification.type"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>

      <div
        v-if="menuService === 'root'"
        class="media d-flex align-items-center"
      >
        <h6 class="font-weight-bolder mr-auto mb-0">
          {{ $t('System Notifications') }}
        </h6>
        <b-form-checkbox
          :checked="enableNotification"
          switch
          @change="switchChange('enableNotification', $event)"
        />
      </div>
      <div v-if="menuService === 'root'">
        <b-link
          v-for="notification in systemNotifications"
          :key="notification.subtitle"
        >
          <b-media>
            <template #aside>
              <b-avatar
                size="32"
                :variant="notification.type"
              >
                <feather-icon :icon="notification.icon" />
              </b-avatar>
            </template>
            <p class="media-heading">
              <span class="font-weight-bolder">
                {{ notification.title }}
              </span>
            </p>
            <small class="notification-text">{{ notification.subtitle }}</small>
          </b-media>
        </b-link>
      </div>
    </vue-perfect-scrollbar> -->

    <perfect-scrollbar
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-link
        v-for="notification in notifications"
        :key="notification._id"
        @click="clickNotification(notification)"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.avatar"
              :text="notification.avatar"
              :variant="notification.type"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ showFromCurrentLanguage(notification.title) }}
            </span>
          </p>
          <small class="notification-text">{{ showFromCurrentLanguage(notification.subtitle) }} {{ timeSince(notification.created) }}</small>
        </b-media>
      </b-link>

      <div
        v-if="menuService === 'root'"
        class="media d-flex align-items-center"
      >
        <h6 class="font-weight-bolder mr-auto mb-0">
          {{ $t('System Notifications') }}
        </h6>
        <b-form-checkbox
          :checked="enableNotification"
          switch
          @change="switchChange('enableNotification', $event)"
        />
      </div>
      <div v-if="menuService === 'root'">
        <b-link
          v-for="notification in systemNotifications"
          :key="notification._id"
        >
          <b-media>
            <template #aside>
              <b-avatar
                size="32"
                :variant="notification.type"
              >
                <feather-icon :icon="notification.icon" />
              </b-avatar>
            </template>
            <p class="media-heading">
              <span class="font-weight-bolder">
                {{ notification.title }}
              </span>
            </p>
            <small class="notification-text">{{ notification.subtitle }}</small>
          </b-media>
        </b-link>
      </div>
    </perfect-scrollbar>
    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      @click="allNotification()"
    >{{ $t('Read all notifications') }}</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
// import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    PerfectScrollbar,
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    // VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      enableNotification: true,
    }
  },
  computed: {
    menuService() {
      return store.state.appConfig.menuService
    },
    notifications() {
      return store.state.notifications.notifications
    },
    systemNotifications() {
      return store.state.notifications.systemNotifications
    },

  },
  created() {
    this.enableNotification = localStorage.getItem('enableNotification') ? (localStorage.getItem('enableNotification') === 'true') : false
  },
  methods: {
    timeSince(date) {
      const seconds = Math.floor((new Date() - new Date(date)) / 1000)

      let interval = seconds / 31536000

      if (interval > 1) {
        return `${Math.floor(interval)} ${this.$t('years')}`
      }
      interval = seconds / 2592000
      if (interval > 1) {
        return `${Math.floor(interval)} ${this.$t('months')}`
      }
      interval = seconds / 86400
      if (interval > 1) {
        return `${Math.floor(interval)} ${this.$t('days')}`
      }
      interval = seconds / 3600
      if (interval > 1) {
        return `${Math.floor(interval)} ${this.$t('hours')}`
      }
      interval = seconds / 60
      if (interval > 1) {
        return `${Math.floor(interval)} ${this.$t('minutes')}`
      }
      return `${Math.floor(seconds)} ${this.$t('seconds')}`
    },
    clickNotification(data) {
      if (data.subject === 'E-commerce-chat-view') {
        // console.log(JSON.parse(data.params))
        store.dispatch('notifications/readFromApplication', data._id)

        const params = JSON.parse(data.params)
        this.$router.push({ path: `/e-commerce/chat/${params.roomChatId}` })
        // eslint-disable-next-line no-unused-vars
          .catch(e => { })
      }
    },
    showFromCurrentLanguage(data) {
      console.log(data)
      if (data) {
        const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
        if (indexLang > -1) {
          return data[indexLang].value
        }
        return ''
      }
      return ''
    },
    allNotification() {
      // store.commit('notifications/UNSHIFT_NOTIFICATIONS')
      if (this.menuService === 'agent') {
        this.$router.push({ name: 'agent-read-notification' })
      } else if (this.menuService === 'root') {
        this.$router.push({ name: 'root-read-notification' })
      }
    },
    switchChange(name, status) {
      console.log(name, status)
      localStorage.setItem(name, status)
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      // notifications,
      // systemNotifications,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>

</style>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"/>
